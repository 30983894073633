<template lang="pug">
  div
    .d-flex.justify-content-center
      WithdrawalStateCards
    .tabla.sombra
      .mb-2
      h3.mr-3 Retiros, {{$moment(filterDate).locale('es').format('LL')}}
      .d-flex.flex-row
        .ml-auto.mr-2
          b-form-datepicker(
            label-no-date-selected="sin fecha"
            v-model="filterDate"
            reset-button
            @context="fetchData"
          )
      b-table.mt-5.mb-2(:fields="fields" :items="withdrawalsData" v-if="withdrawals")
        template(#cell(address)="value")
          #address.d-flex.flex-row
            span(v-if="value.item.address.length <15") {{ value.item.address}}{{value.item.deptoNumber}}
            span(v-else v-b-tooltip :title="value.item.address") {{value.item.address.substring(0,15) + '...'}}{{value.item.deptoNumber}}
        template(#cell(isAssign)="value")
          #Assign.d-flex.flex-row.justify-content-center
            b-form-checkbox(v-model="value.item.isAssign" name="check-button" @change="updateAssign(value.item)" switch)
        template(#cell(comment)="value")
          #Commentary.d-flex.flex-row.justify-content-center
            span(v-if="!edit.value && !edit.id && value.item.comment.length >= 25" v-b-tooltip :title="value.item.comment") {{ value.item.comment.substring(0,25) + '...' }}
            span(v-if="!edit.value && !edit.id && value.item.comment.length < 25") {{value.item.comment}}
            b-form-textarea.mr-2(
              v-if="edit.value && edit.id == value.item.withdrawal.id"
              id="textarea"
              v-model="value.item.comment"
              placeholder="Enter comment..."
              rows="3"
              max-rows="6"
              max-length ="255"
            )
            span(@click="setEdit(value.item)" style="cursor:pointer").material-icons.icon-color-sm.ml-auto edit
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import WithdrawalStateCards from './WithdrawalStateCards.vue'

export default {
  name: 'ListWithdrawal',
  components: {
    WithdrawalStateCards
  },
  created () {
    this.filterDate = this.$moment(new Date(new Date().setDate(new Date().getDate() + 1))).format('YYYY-MM-DD')
    this.fetchData()
  },
  data () {
    return {
      filterDate: null,
      edit: { value: false, id: null },
      fields: [
        { label: 'Nombre Tienda', key: 'name' },
        { label: 'Direccion de retiro', key: 'address' },
        { label: 'Comuna', key: 'commune' },
        { label: 'Comentario', key: 'comment' },
        { label: 'Instagram', key: 'accountName' },
        { label: 'Telefono', key: 'accountPhone' },
        { label: 'Encargado Entrega', key: 'storeOwner' },
        { label: 'Planilla OK', key: 'isAssign' }
      ]
    }
  },
  computed: {
    ...mapGetters(['withdrawals']),
    withdrawalsData () {
      if (this.withdrawals.length > 0) {
        return this.withdrawals.map(withdrawal => {
          return {
            withdrawal,
            name: withdrawal.store.name,
            address: withdrawal.address.street,
            deptoNumber: `${withdrawal.address.streetNumber ? ', ' + withdrawal.address.streetNumber : ''} ${withdrawal.address.apartment ? ', ' + withdrawal.address.apartment : ''}`,
            commune: withdrawal.address.commune,
            comment: withdrawal.commentary,
            isAssign: withdrawal.isAssign,
            accountName: withdrawal.store.contactAccountName ? withdrawal.store.contactAccountName : '',
            accountPhone: withdrawal.store.contactPhone ? withdrawal.store.contactPhone : '',
            storeOwner: withdrawal.store.storeOwner ? withdrawal.store.storeOwner : ''
          }
        })
      }
      return []
    }
  },
  methods: {
    ...mapActions(['listWithdrawal', 'updateWithdrawal', 'getWithdrawalCountStates']),
    async setEdit (withdrawal) {
      if (!this.edit.value) this.edit.value = true
      if (this.edit.value && !this.edit.id) {
        this.edit.id = withdrawal.withdrawal.id
        return
      }
      if (this.edit.value && this.edit.id) {
        this.edit.id = null
        this.edit.value = false
        const data = { ...withdrawal.withdrawal }
        data.commentary = withdrawal.comment
        await this.updateWithdrawal({ id: withdrawal.withdrawal.id, data })
        await this.fetchData()
      }
    },
    async updateAssign (withdrawal) {
      const data = { ...withdrawal.withdrawal }
      data.isAssign = withdrawal.isAssign
      await this.updateWithdrawal({ id: withdrawal.withdrawal.id, data })
      await this.fetchData()
    },
    async fetchData () {
      await this.listWithdrawal({
        params: {
          date: this.$moment(this.filterDate).format('YYYY-MM-DD')
        }
      })
      await this.getWithdrawalCountStates({ params: { date: this.$moment(this.filterDate).format('YYYY-MM-DD') } })
    }
  }
}
</script>

<style scoped>
::v-deep .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #00C5AC;
    background-color: #00C5AC;
}
</style>
