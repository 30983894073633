import axios from '../../plugins/axios'

export const listDelivery = ({ commit }, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: payload ? payload.params : null
  }
  return axios.get('/delivery', config)
    .then(response => {
      commit('SET_DELIVERIES', { deliveries: response.data.deliveries })
      return response
    })
    .catch(err => err.response)
}

export const listDriverDelivery = ({ commit }, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: payload ? payload.params : null
  }
  commit('SET_LOADING', false)
  commit('SET_LOAD_DRIVER_ROUTES', true)
  return axios.get(`/driver/${payload.driverId}/delivery`, config)
    .then(response => {
      commit('SET_DELIVERIES', { deliveries: response.data })
      const finalRoute = [...response.data].filter((d) => d.order === -1000)
      if (finalRoute.length > 0) commit('SET_FINAL_ROUTE', { finalRoute: finalRoute[0] })
      commit('SET_LOADING', true)
      commit('SET_LOAD_DRIVER_ROUTES', false)
      return response
    })
    .catch(err => err.response)
}

export const getDelivery = ({ commit }, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  commit('SET_DELIVERY', { delivery: null })
  return axios.get(`/delivery/${payload.id}`, config)
    .then((response) => {
      commit('SET_DELIVERY', { delivery: response.data })
      return response
    })
    .catch(err => err.response)
}

export const bulkCreateDelivery = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.post('/bulk_delivery', payload.data, config)
    .then(response => response)
    .catch(err => err.response)
}

export const createDelivery = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.post('/delivery', payload.data, config)
    .then(response => response)
    .catch(err => err.response)
}

export const deleteDelivery = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.delete(`/delivery/${payload.id}`, config)
    .then(response => response)
    .catch(err => err.response)
}

export const updateDelivery = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.put(`/delivery/${payload.id}`, payload.data, config)
    .then(response => response)
    .catch(err => err.response)
}
export const scannInStore = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.put(`/scanner/store/delivery/${payload.id}`, payload.data, config)
    .then(response => response)
    .catch(err => err.response)
}
export const scannInWarehouse = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.put(`/scanner/warehouse/delivery/${payload.id}`, payload.data, config)
    .then(response => response)
    .catch(err => err.response)
}

export const createDeliveryImage = (_, { data, deliveryId }) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } }

  return axios.post(`/delivery-image/${deliveryId}/image`, data, config)
    .then((response) => console.log(response))
    .catch((error) => error.response)
}

export const updateDriverDeliveryOrder = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.put(`/driver/${payload.driverId}/delivery-bulk-order`, payload.data, config)
    .then(response => response)
    .catch(err => err.response)
}

export const bulkUpdateDeliveryDriver = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.put('/bulk-delivery-change-driver', payload.data, config)
    .then(response => response)
    .catch(err => err.response)
}

export const bulkDeleteDelivery = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` }, data: payload.data }
  return axios.delete('/bulk-delete-delivery', config)
    .then(response => response)
    .catch(err => err.response)
}

export const bulkUpdateDelivery = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.put('/bulk-update-delivery', payload.data, config)
    .then(response => response)
    .catch(err => err.response)
}

export const bulkUpdateDeliveryDate = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.put('/bulk-delivery-update-date', payload.data, config)
    .then(response => response)
    .catch(err => err.response)
}

export const getCountStates = ({ commit }, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: payload ? payload.params : null
  }
  return axios.get('/delivery-state-count', config)
    .then(response => {
      commit('SET_COUNT_STATES', { countStates: response.data })
      return response
    })
    .catch(err => err.response)
}

export const createFinalRoute = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.post(`/driver/${payload.driverId}/create_final_route`, payload.data, config)
    .then(response => response)
    .catch(err => err.response)
}

export const getGeocode = async (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  const data = { address: payload }
  const response = await axios.post('/delivery-get-geocode', data, config)
  console.log(response)
  return response
}

export const getAutocomplete = ({ commit }, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: payload ? payload.params : null
  }
  return axios.get('/delivery-get-autocomplete', config)
    .then(response => {
      commit('SET_AUTOCOMPLETE_ADDRESSESS', response.data)
    })
    .catch(err => err.response)
}
