<template lang="pug">
#ScannerInStore
  //- h4 hola soy la vista scanner en tienda {{ code }}
  qrcode-stream.qr-container(@detect="onDetect")
  .qr-container-loader.text-center(v-if="loader")
    b-spinner.float
  //- b-button
  //- .iconList(style="font-size: 3.3rem;")
  //-   b-icon.rounded-circle.bg-white.p-3(icon="list-ul" variant="dark")
  //- span.text-white(style="font-size: 1.2rem;") 33
  b-button.bg-white(v-b-modal.scanned-success-deliveries-modal style="border-radius: 30px; margin-bottom: 3rem; padding: 0.71rem; width: fit-content; display: flex;")
    b-icon(style="padding-top: 0.12rem; color: #181818;" icon="list-ul")
    span.pl-2.text-grey(v-if="scannedSuccess") {{ scannedSuccess }}

  b-modal#scanned-success-deliveries-modal(ref="scannedSuccessDeliveries" hide-footer title="Pedidos escaneados" centered)
    scanned-deliveries(:deliveries="deliveries")

</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import { mapActions } from 'vuex'
import ScannedDeliveries from './ScannedDeliveries.vue'

export default {

  components: {
    QrcodeStream,
    ScannedDeliveries
  },
  data () {
    return {
      code: [],
      deliveries: [],
      scannedSuccess: 0,
      loader: false
    }
  },
  methods: {
    ...mapActions(['scannInStore']),
    async onDetect (promise) {
      try {
        this.loader = true
        const {
          // imageData, // raw image data of image/frame
          content // decoded String
          // location // QR code coordinates
        } = await promise

        const deliveryId = Number(content)
        if (deliveryId) {
          const response = await this.scannInStore({ id: deliveryId })
          if (response.status >= 200 && response.status < 300) {
            this.loader = false
            this.code.push(content)
            this.deliveries.push(response.data)
            this.scannedSuccess += 1
            // console.log(imageData, 'Dataimg', content, 'content', location, 'location')

            return this.$bvToast.toast('Escaneado con éxito', {
              title: `Paquete ${content}`,
              variant: 'success',
              toaster: 'b-toaster-top-center',
              autoHideDelay: 7000,
              solid: true
            })
          } else if (response.status >= 400) {
            this.loader = false
            console.log('Error al intentar cambiar estado')
            return this.$bvToast.toast(response.data.error || 'Ocurrió un error', {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 7000,
              solid: true
            })
          }
        } else {
          this.loader = false
          return this.$bvToast.toast(`Código de paquete inválido: ${content}`, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 7000,
            solid: true
          })
        }
        // ...
      } catch (error) {
        this.loader = false
        console.log('error al intentar leer Código Qr')
        return this.$bvToast.toast('Ocurrió un error', {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 7000,
          solid: true
        })
      }
    }
  }
}

</script>

<style lang="scss" scoped>
#ScannerInStore {
  background-color: #191919;
  height: 100%;
  display: flex;
  flex-direction: column;
  .qr-container {
    padding: 0.4rem 0rem;

    .qrcode-stream-camera {
      border-radius: 15px;
    }
  }
  .qr-container-loader {
    /* border: solid 1px red; */
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    height: calc(100% - 170px);
    position: absolute;
    width: 93%;
  }

  .iconList {
    /* position: absolute; */
  }
}
</style>
<style lang="scss">
#scanned-success-deliveries-modal {
  .modal-dialog-centered {
    align-items: stretch !important;
    padding-top: 40% !important;
  }
  .modal-content {
    border-radius: 16px 16px 0 0 !important;
    transition-timing-function: ease-out !important;
    transition-duration: 300ms !important;

    .modal-body {
      padding: 0.7rem 0.4rem;
    }
    .table th, .table td {
      padding: 0.4rem;
      vertical-align: middle;
      font-size: 15px;
      font-weight: 400;
    }
  }
}
</style>
