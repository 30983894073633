<template lang="pug">
  .state-count-card.sombra
    .state-count-card-icon-container(:style="`background-color: ${color}`")
      .material-icons-outlined(v-if="icon!=='assignment_return'" style="font-size: 20px;") {{icon}}
      .material-icons-outlined(v-if="icon==='assignment_return'" style="font-size: 20px; transform: scaleX(-1)") {{icon}}

    .state-count-card-body
      div(style="font-size: 14px;") {{stateCount}}
      div(style="font-size: 13px; white-space: nowrap;") {{stateName}}
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'var(--primary)'
    },
    stateName: {
      type: String,
      default: 'estado'
    },
    stateCount: {
      type: [String, Number],
      default: ''
    },
    icon: {
      type: String,
      default: 'local_shipping'
    }
  }
}
</script>

<style lang="scss" scoped>
.state-count-card {
  display: flex;
  height: 50px;
  &-body {
    .material-icons-outlined {
      font-size: 22px !important;
    }
    font-weight: 700;
    color: #495057;
    display: flex;
    padding: 4px;
    padding-left: 12px;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
  &-icon-container {
    color: white;
    display: flex;
    width: 55px;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
</style>
