<template lang="pug">
.tabla.sombra
  b-table(
    :fields="fields" :items="deliveries"
    responsive
  )
    template(#cell(store)="{item}")
      p {{ item.store? item.store.name : '-' }}

</template>

<script>

export default {
  props: {
    deliveries: Array
  },
  data () {
    return {
      fields: [
        { label: 'Código de envío', key: 'id' },
        { label: 'Destinatario', key: 'customerName' },
        { label: 'Tienda', key: 'store' }
      ]
    }
  },
  mounted () {
    // console.log('Montada', this.deliveries)
  },
  created () {
    // console.log('creada', this.deliveries)
  }
}

</script>

<style lang="scss">
.tabla {
  padding: 0;
}
.sombra {
  box-shadow: 0px 1px 1px #00000029;
}
</style>
