<template lang="pug">
  #upload-deliveries-from-exel
    .loading(v-if="loading")
      .text-center.mb-2 {{ loadingText }}
      b-spinner.m-auto
    div(v-else-if="step===1").text-center
      input.d-none(ref="inputFile" type="file" name="file" @change="setFile")
      b-button(@click="() => this.$refs.inputFile.click()") Cargar archivo
        .material-icons upload
    div(v-else-if="step===2")
      .mb-2 Se encontraron varias paginas, selecciona las que deseas ingresar y has click en Siguente
      b-form-checkbox-group(v-model="sheets" :options="sheetsOptions" stacked)
      b-button(@click="step=3") Siguente
    div(v-else-if="step===3")
      div Se cargaron correctamente {{deliveries.length}} envíos
      div.text-danger(v-if="errorCount > 0") {{errorCount}} datos nulos
      b-button(@click="onSubmit") Guardar envíos
    div(v-else-if="step===4")
      div {{ loadingText }}
</template>

<script>
import xlsxFile from 'read-excel-file'
import { mapActions } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  data () {
    return {
      loadingText: 'Cargando archivo',
      step: 1,
      file: null,
      sheets: [],
      sheetsOptions: [],
      loading: false,
      deliveries: [],
      errorCount: 0,
      schema: {
        Tienda: {
          prop: 'storeName',
          type: String,
          required: true
        },
        'Fecha Ingreso': {
          prop: 'date',
          type: Date
        },
        Nombre: {
          prop: 'customerName',
          type: String,
          required: true
        },
        Direccion: {
          prop: 'address',
          type: String,
          required: true
        },
        Comuna: {
          prop: 'commune',
          type: String,
          required: true
        },
        Telefono: {
          prop: 'customerPhone',
          type: String
        },
        Conductor: {
          prop: 'driverName',
          type: String
        }
      }
    }
  },
  methods: {
    ...mapActions(['bulkCreateDelivery']),
    async onSubmit () {
      this.loading = true
      this.loadingText = 'Registrando los envíos'
      const response = await this.bulkCreateDelivery({ data: this.deliveries })
      if (response.status === 200) {
        this.makeSuccessToast('Los se registraron correctamente')
        this.loadingText = 'Los envíos se registraron correctamente'
        this.$emit('done')
      } else if (response.status >= 400) {
        this.loadingText = 'Error al registrar envíos'
        this.makeDangerToast(response.data.message || 'Error al crear la envios')
      }

      this.step = 4
      this.loading = false
    },
    setFile (event) {
      this.file = event.target.files[0]
    }
  },
  watch: {
    async file () {
      this.loading = true
      const sheets = await xlsxFile(this.file, { getSheets: true })
      this.sheetsOptions = sheets.reduce((acum, item) => acum.concat({
        value: item.name,
        text: item.name
      }), [])
      this.step = 2
      this.loading = false
    },
    async step () {
      if (this.step === 2 && this.sheetsOptions.length === 1) {
        this.sheets = [this.sheetsOptions[0].value]
        this.step = 3
      }
      if (this.step === 3) {
        this.loading = true
        let deliveries = []
        let count = 0
        this.loadingText = `Cargando plantilla (${count} / ${this.sheets.length})`
        for (const sheet of this.sheets) {
          const { rows, errors } = await xlsxFile(this.file, { schema: this.schema, sheet })
          deliveries = deliveries.concat(rows)
          count++
          this.loadingText = `Cargando plantilla (${count} / ${this.sheets.length})`
          this.errorCount += errors.length
        }
        this.deliveries = deliveries
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#upload-deliveries-from-exel {
  .loading {
    display: flex;
    justify-content: center;
    height: 200px;
    flex-direction: column;
  }
}
</style>
