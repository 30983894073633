const reasons = {
  LOST_DELIVERY: 'Envío extraviado',
  IN_ORIGIN_PLANT: 'En planta de origen',
  IN_TRANSIT: 'En tránsito',
  IN_DESTINATION_PLANT: 'En planta destino',
  OUT_FOR_DELIVERY: 'En Reparto',
  DELIVERED: 'Entregado',
  AVAILABLE_FOR_WITHDRAWAL: 'Disponible para retiro',
  ADDRESS_NOT_FOUND: 'Dirección no encontrada',
  REJECTED_BY_RECIPIENT: 'Rechazado por destinatario',
  NO_ONE_TO_RECEIVE: 'No hay quien reciba',
  DAMAGED_DELIVERY: 'Envío dañado',
  DELIVERY_ATTEMPT_FAILED: 'Envío no pudo ser entregado',
  RETURN_DELIVERED: 'Devolución entregada',
  RETURN_IN_DELIVERY: 'Devolución en reparto',
  DELIVERY_FAILED: 'Entrega fallida',
  RETURN_TO_SENDER: 'Devolución a remitente',
  WRONG_ADDRESS: 'Dirección incorrecta',
  OUT_OF_COVERAGE: 'Fuera de cobertura'
}

export default reasons
