<template lang="pug">
  #DeliveryDriverEdit
    b-card.delivery-card
      div.text-secondary.mb-2 {{ delivery && delivery.store ? delivery.store.name : 'No encontrado' }}
      b-form
        .box-form
          .material-icons.icon-input store
          b-form-group(label-for="commune-input")
            .input-title Comuna
             b-form-input#commune-input.input(
              v-model="form.commune"
              ref="commune"
            )
        hr.linea
        .box-form
          .material-icons.icon-input store
          b-form-group(
            label-for="store-input"
          )
            .input-title Calle, Número
            b-form-input(
              ref="autocomplete"
              id="street-input"
              v-model="form.street"
              style="border:1px solid #fff;"
            )
            hr(style="margin-top: -6px").linea
      div.text-light-primary.mb-2 {{ delivery && delivery.customerName }}
      div.text-light-secondary(style="font-weight:bold") {{ delivery && delivery.customerPhone }}
      .d-flex
        .advert(v-if="!(form.commune && form.street)")
          span Rellene todo los formularios
        .ml-auto
          b-button.boton(v-if="form.commune && form.street" @click="openModal = true") Guardar Cambios
      b-modal(v-model="openModal" hide-footer)
        #modalDelete
          .d-flex.flex-column.text-center
            span.h4 ¿Estas seguro que desea editar la direccion?
            .d-flex.flex-row
              b-button.ml-auto(@click="openModal = false") No
              b-button.ml-2.mr-2(@click="editDriver") Si

</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
// import Autocomplete from '../UI/Autocomplete'
// import settings from '../../../settings'

export default {
  name: 'DeliveryDriverEdit',
  components: {
    // Autocomplete
  },
  mounted () {
    // const key = settings.mapsKey

    // create the script element to load
    /* const googleMapScript = document.createElement('SCRIPT')
    googleMapScript.setAttribute(
      'src',
      `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback=initAutocomplete`
    )
    googleMapScript.setAttribute('defer', '')
    googleMapScript.setAttribute('async', '')
    document.head.appendChild(googleMapScript)
    this.initAutocomplete() */
  },
  data () {
    return {
      form: {
        street: '',
        commune: ''
      },
      autocomplete: null,
      openModal: false,
      previousInput: 0
    }
  },
  computed: {
    ...mapGetters(['delivery', 'editDelivery'])
  },
  methods: {
    ...mapActions(['updateDelivery', 'createDeliveryImage', 'getDelivery']),
    ...mapMutations(['SET_EDIT_DELIVERY']),
    initAutocomplete () {
      const address1Field = document.getElementById('street-input')
      console.log(address1Field)
      window.initAutocomplete = () => {
        console.log(this.$refs.autocomplete)
        this.autocomplete = new window.google.maps.places.Autocomplete(address1Field, {
          componentRestrictions: { country: ['cl'] },
          fields: ['address_components', 'geometry'],
          types: ['address']
        })
        this.autocomplete.addListener('place_changed', () => {
          const place = this.autocomplete.getPlace()
          let streetNumber = ''
          let route = ''
          for (const component of place.address_components) {
            const componentType = component.types[0]
            if (componentType === 'administrative_area_level_3') this.form.commune = component.short_name
            if (componentType === 'street_number') streetNumber = component.short_name
            if (componentType === 'route') route = component.short_name
          }
          this.form.street = `${route} ${streetNumber}`
          this.$ref.commune.focus()
        })
      }
    },
    async editDriver () {
      const data = { ...this.delivery }
      data.address.street = this.form.street.split(',')[0]
      data.address.commune = this.form.commune
      const payload = {
        id: data.id,
        data
      }
      const response = await this.updateDelivery(payload)
      if (response.status === 201 || response.status === 200) {
        this.$bvToast.toast('Se ha editado correctamente el envio', {
          variant: 'success',
          autoHideDelay: 7000
        })
        this.openModal = false
        this.SET_EDIT_DELIVERY(false)
      } else if (response.status >= 400) {
        this.$bvToast.toast(response.data.message || 'Error al editar el envio', {
          variant: 'danger',
          autoHideDelay: 7000
        })
      }
    }
  },
  watch: {
    'delivery' (value) {
      if (value && value.address) {
        this.form.commune = value.address.commune
        this.form.street = value.address.street
      }
    }
  },
  beforeDestroy () {
    this.SET_EDIT_DELIVERY(false)
  }
}
</script>
