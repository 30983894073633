<template lang="pug">
  #DeliveryStateCards
    b-row(cols="12" cols-md="2" cols-lg="6")
      b-col.mb-2.pr-0
        state-count-card(
          stateName="Por Entregar"
          color="#0086ce"
          icon="local_shipping"
          :stateCount="countStates['por entregar']"
        )
      b-col.mb-2.pr-2.pl-2
        state-count-card(
          stateName="Retirado"
          color="#52e0ff"
          icon="assignment_return"
          :stateCount="countStates['retirado']"
        )
      b-col.mb-2.pr-2.pl-0
        state-count-card(
          stateName="Recepcionado"
          color="#3cd5b0"
          icon="assignment_turned_in"
          :stateCount="countStates['recepcionado']"
        )
      b-col.mb-2.pr-2.pl-0
        state-count-card(
          stateName="Entregado"
          color="#2fd15c"
          icon="inventory"
          :stateCount="countStates['entregado']"
        )
      b-col.mb-2.pr-2.pl-0
        state-count-card(
          stateName="Pendiente"
          color="#f1b44c"
          icon="pending_actions"
          :stateCount="countStates['pendiente']"
        )
      b-col.mb-2.pl-0
        state-count-card(
          stateName="Rechazado"
          color="#f46a6a"
          icon="thumb_down"
          :stateCount="countStates['rechazado']"
        )
</template>

<script>
import StateCountCard from '../UI/StateCountCard'
import { mapGetters } from 'vuex'

export default {
  components: {
    StateCountCard
  },
  computed: {
    ...mapGetters(['countStates'])
  }
}
</script>
