<template lang="pug">
#RecoverPassword
    div(v-if="result === false")
      center
        img(src="../../../public/FASTER_logo.png" style="width: 300px")
        h5.text-center Recuperar contraseña
        p.texto Ingresa tu mail para enviar instrucciones de recuperación de contraseña
      b-form(@submit="onSubmit")
        .box-form
          .material-icons.icon account_box
          .group
            b-form-group.texto(label-for="input-email")
                .title(style="margin-left:12px")
                .title Email
                b-form-input.input(
                  id="input-email"
                  type="email"
                  placeholder="Ingresa tu correo"
                  v-model="email"
                  style="color:white"
                  required)
        hr
        center
          .botones
            b-button.boton(type="submit" style="margin-top:20px") Enviar
    div(v-else)
      h5.text-center Email enviado, revisa tu correo y sigue las instrucciones de recuperación
      router-link(:to="{ name: 'Login' }") Volver
</template>
<script>
import { mapActions } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  data () {
    return {
      email: '',
      result: false
    }
  },
  methods: {
    ...mapActions(['sendRecoverPasswordEmail']),
    async onSubmit (event) {
      event.preventDefault()
      const response = await this.sendRecoverPasswordEmail({ email: this.email })
      if (response) {
        this.result = true
      } else {
        this.makeDangerToast('Error en el correo enviado')
      }
    }
  }
}
</script>
<style lang="scss">
@import '../../../src/styles/colors.scss';
#RecoverPassword {
  margin: auto;
  padding: 16px;
  max-width: 300px;
  color: $card-bg;
}
.texto {
  font-size: 15px;
  color:var(--light-primary);
}
.botones {
  display: flex;
  flex-direction: column;
}
  hr{
    background: $card-bg;
    margin-top: -10px;
  }
  .title{
    color: $card-bg;
    margin-left:12px;
    font-weight: bold;
    font-size: 13px;
  }
  .icon{
    padding: 0px;
    margin-top: 16px;
    font-size: 30px;
  }
</style>
