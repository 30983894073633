<template lang="pug">
b-container#ScannerContainer(style="padding-top:40px")
  scanner-in-store
</template>

<script>
import ScannerInStore from '../components/delivery/ScannerInStore.vue'

export default {
  components: {
    ScannerInStore
  }
}
</script>

<style lang="scss">
#ScannerContainer{
  background-color: #191919;
  height: calc(100% - 60px);
}
</style>
