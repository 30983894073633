import { render, staticRenderFns } from "./ListDriverDelivery.vue?vue&type=template&id=00922846&scoped=true&lang=pug"
import script from "./ListDriverDelivery.vue?vue&type=script&lang=js"
export * from "./ListDriverDelivery.vue?vue&type=script&lang=js"
import style0 from "./ListDriverDelivery.vue?vue&type=style&index=0&id=00922846&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00922846",
  null
  
)

export default component.exports