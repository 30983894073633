<template lang="pug">
  .list-driver-delivery
    b-spinner.d-flex.justify-content-center(
      v-if="loadDriverRoutes"
      style="width: 5rem;position: absolute;top: 45%;left: 47.5%;height: 5rem;"
    )
    draggable(v-model="deliveryList" handle=".delivery-card-drag-icon" @end="updateOrder")
      delivery-card.mb-3(
        v-if="!loadDriverRoutes"
        v-for="(delivery, index) in deliveryList"
        :storeName="delivery.store ? delivery.store.name : 'No encontrado'"
        :address="delivery.address.street"
        :commune="delivery.address.commune"
        :customerName="delivery.customerName"
        :deliveryId="delivery.id"
        :notGeocodeFound="delivery.notFound ? delivery.notFound : false"
        :draggable="draggable"
        :order="delivery.order"
        :key="delivery.id"
        :lat="delivery.address.latCoordinate"
        :lng="delivery.address.longCoordinate"
        :delivery="delivery"
        @changeDriver="$emit('changeDriver', delivery)"
        @done="$emit('editFinalRoute')"
      )
    b-button.create-delivery-btn(:to="{ name: 'CreateDriverDelivery' }")
      .material-icons add
    // b-button.optimize-delivery-btn
      | Optimizar Rutas
</template>

<script>
import DeliveryCard from './DeliveryCard'
import draggable from 'vuedraggable'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    deliveries: {
      type: Array,
      default: () => []
    },
    draggable: {
      type: Boolean,
      default: false
    },
    showCreateButton: {
      type: Boolean,
      default: true
    }
  },
  components: {
    DeliveryCard,
    draggable
  },
  data () {
    return {
      deliveryList: []
    }
  },
  methods: {
    ...mapActions(['updateDriverDeliveryOrder']),
    updateOrder () {
      const data = this.deliveryList.reduce((acum, delivery, index) => acum.concat({
        id: delivery.id,
        order: index
      }), [])
      this.updateDriverDeliveryOrder({ driverId: this.user.id, data })
    }
  },
  watch: {
    deliveries () {
      this.deliveryList = this.deliveries
    }
  },
  computed: {
    ...mapGetters(['user', 'loadDriverRoutes'])
  }
}
</script>

<style lang="scss" scoped>
.list-driver-delivery {
  .create-delivery-btn {
    display: flex;
    align-content: center;
    padding: 0;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    position: fixed;
    bottom: 80px;
    right: 30px;
    .material-icons {
      font-size: 50px;
      margin: auto;
    }
  }
  .optimize-delivery-btn {
    display: flex;
    align-content: center;
    padding: 0;
    height: 55px;
    width: 65px;
    position: fixed;
    bottom: 160px;
    right: 30px;
    font-size: 12px;
  }
}
</style>
