<template lang="pug">
.package-label-template
  .header
    //- img(src="@/assets/images/logo-etiqueta-envios.png" style="height: 9.69mm; width: 9.69mm;")
    div.text-center
      .store-name {{ context.storeName }}
      div ID envío: {{ context.id }}
  .divider
  .content.brand.text-center FASTER
    //- span(v-if="context.changeDate")  (CAMBIO / DEVOLUCIÓN)
  .divider
  .content
    //- .logo-store
      //- img(v-if="context.storeLogo" :src="context.storeLogo" style="height: 12mm; width: 12mm; margin")
    .commune {{ context.commune }}
    .qr-code
      //- img#barcode
      img(v-if="context.qrCode" :src="context.qrCode" alt="código-qr")
      .delivery-code.text-center {{ context.id }}
  .divider
  .content.info
    .info-item Destinatario: {{ context.customerName }}
    .info-item Dirección: {{ context.customerAddress }}
    .info-item Teléfono: {{ context.customerPhone }}
    .info-item Comentario: {{ context.comentary }}
    //- .info-item(v-if="context.changeDate") Fecha cambio: {{ context.changeDate }}
</template>

<script>
// import JsBarcode from 'jsbarcode'

export default {
  props: {
    context: {
      type: Object,
      default: () => ({
        storeName: '?',
        customerName: '-',
        customerPhone: '-',
        customerAddress: '-',
        customerFullAddress: '-',
        comentary: '-',
        commune: '-',
        qrCode: '-'
        // barcode: 123456789,
        // changeDate: null
      })
    }
  },
  data () {
    return {
    }
  },
  mounted () {
    // JsBarcode('#barcode', this.context.barcode, {
    //   format: 'CODE128',
    //   lineColor: '#000',
    //   width: 1,
    //   height: 2,
    //   margin: 1,
    //   displayValue: false
    // })
  }
}
</script>
