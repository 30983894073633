<template lang="pug">
  .tabla.sombra
    .mb-2
    h3.mr-3 Ingresar nuevo retiro
    .mt-4.mb-2
      span.fw-bold.sub-title Datos del retiro
    .row(v-if="newWithdrawal &&  withdrawals.length === 0")
      .col
        .box-form
          .material-icons.icon-input place
          b-form-group(
            label-for="street-input"
          )
            .input-title Dirección Retiro
            b-form-input#street-input.input(
              v-model="form.address.street"
              placeholder="Incluir Dept. de ser necesario"
              maxlength="256"
            )
        hr.linea
      .col
        .box-form
          .material-icons.icon-input place
          b-form-group(
            label-for="commune-input"
          )
            .input-title Comuna Retiro
            b-form-input#commune-input.input(
              v-model="form.address.commune"
              maxlength="256"
            )
        hr.linea
      .col
        .box-form
          b-icon(icon="instagram").icon-input
          b-form-group(
            label-for="account-input"
          )
            .input-title Instagram
            b-form-input#account-input.input(
              v-model="storeForm.contactAccountName"
              maxlength="256"
            )
        hr.linea
      .col
        .box-form
          .material-icons.icon-input call
          b-form-group(
            label-for="phone-input"
          )
            .input-title Telefono de Contacto
            b-form-input#phone-input.input(
              v-model="storeForm.contactPhone"
              maxlength="256"
            )
        hr.linea
      .col
        .box-form
          .material-icons.icon-input account_circle
          b-form-group(
            label-for="phone-input"
          )
            .input-title Encargado entrega
            b-form-input#phone-input.input(
              v-model="storeForm.storeOwner"
              maxlength="256"
            )
        hr.linea
    .row.mb-4.mt-2(v-if="!newWithdrawal &&  withdrawals.length === 0")
      .col.d-flex.flex-column
        .mr-2.flex-row.d-flex(v-if="notValidDay")
          .material-icons.icon-color.mr-2 sentiment_very_dissatisfied
          span.d-block.mt-1 Lamentablemente el dia de mañana no podremos realizar retiros
        .mt-2.flex-row.d-flex(v-if="lateHours")
          .material-icons.icon-color.mr-2 watch_later
          span Para poder agendar retiros, tiene que ser entre las 9:00 am y 10:00 pm
        .mt-2.flex-row.d-flex(v-if="isException && exceptions.length > 0")
          .material-icons.icon-color.mr-2 info
          span(v-if="!noExceptionAllowed") {{exceptions[0].comment}}
          div(style="font-weight:bold; text-align: center;").d-flex.flex-column(v-if="noExceptionAllowed")
            span De momento no tenemos ruta para la fecha seleccionada.
            span Nuestra atención por Instagram es de lunes a viernes de 9 a 23 horas.
            span Nuestras rutas son de Martes a Sabádo.
    #AlreadySchedule(v-if="withdrawals.length > 0")
      .row.mb-4
        .col-md-8.d-flex.flex-row
          div.material-icons.icon-color.mr-2 sentiment_very_satisfied
          div(style="font-weight:bold; text-align: center;")
            span Gracias por agendar, te recordamos que el plazo maximo para tener lista la planilla es hasta las 22 hrs y es muy importante que nos avises.
            | Los retiros se realizan de 8 a 12 hrs aproximadamente y cada paquetito  debe llevar todos los datos del cliente, además el logo identificador de tu tienda&#128522&#128588
      .row.mb-4
        .col
          b-table(:fields="fields" :items="withdrawalData")
            template(#cell(state)="value")
              #Assign.d-flex.flex-row.justify-content-center.assignBox(
                :style="value.item.state? 'background-color:#3DF9DE; color: black;': 'background-color:#3DF9DE; color: black;'"
              )
                span {{value.item.state ? 'Asignado' : 'No Asignado'}}
            template(#cell(address)="value")
              #address.d-flex.flex-row
                div.mr-2(v-if="!edit")
                  span(v-if="value.item.address.length <15") {{ value.item.address}}
                  span(v-else v-b-tooltip :title="value.item.address") {{value.item.address.substring(0,15) + '...'}}
                b-form-input(
                  v-if="edit"
                  v-model="value.item.address"
                  style="width: 50%;"
                )
                span(@click="setEdit(value.item)" style="cursor:pointer").material-icons.icon-color-sm.ml-2 edit
            template(#cell(commune)="value")
              #address.d-flex.flex-row
                span.mr-2(v-if="!edit") {{ value.item.commune}}
                b-form-input(
                  v-if="edit"
                  v-model="value.item.commune"
                  style="width: 50%;"
                )
                span(@click="setEdit(value.item)" style="cursor:pointer").material-icons.icon-color-sm.ml-2 edit
          hr.linea

    .row
      .col-md-3
        .d-flex.flex-column
          span.sub-title Datos de la tienda
          .d-flex.flex-row.mt-4
            .material-icons.icon-color.mr-2 store
            span {{store.name}}
          .d-flex.flex-row.mt-1
            .material-icons.icon-color.mr-2 account_circle
            span {{store.storeOwner? store.storeOwner : `${store.users[0].name} ${store.users[0].lastName}` }}
          .d-flex.flex-row.mt-1
            .material-icons.icon-color.mr-2 call
            span {{store.contactPhone? store.contactPhone : ''}}
          .d-flex.flex-row.mt-1
            b-icon(icon="instagram").icon-color.mr-2
            span {{store.contactAccountName? store.contactAccountName : ''}}
          .d-flex.flex-row.mt-1
            .material-icons.icon-color.mr-2 place
            span {{store.address.street.length <20 ?store.address.street: store.address.street.substring(0,20) + '...'}}{{store.address.streetNumber ? ', ' + store.address.streetNumber : ''}}{{store.address.apartment ? ', ' + store.address.apartment : ''}}
          .d-flex.flex-row.mt-1
            .material-icons.icon-color.mr-2 place
            span {{store.address.commune}}
      // .col-md-3
        .d-flex.flex-column
          span.sub-title Datos del cliente
          .d-flex.flex-row.mt-4
            .material-icons.icon-color.mr-2 account_circle
            span {{`${store.users[0].name} ${store.users[0].lastName}` }}
          .d-flex.flex-row.mt-1
            .material-icons.icon-color.mr-2 call
            span {{`${store.users[0].name} ${store.users[0].lastName}` }}
          .d-flex.flex-row.mt-1
            .material-icons.icon-color.mr-2 place
            span {{store.address.street}}
      .col-md-9
        .d-flex.flex-column
          div(style="margin-top: 9rem" v-if="withdrawals.length === 0 && newWithdrawal && !lateHours").ml-auto.mr-2
            b-button(@click="setWithdrawal()") Programar
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin.vue'
import monthString from '../../shared/monthString'

export default {
  mixins: [MakeToastMixin],
  name: 'WithdrawalForm',
  async created () {
    // Consulta al back para verificar horario de 9am a 22pm
    const resp = await this.checkWithdrawalHour()
    this.lateHours = resp.response
    console.log(resp)
    const user = JSON.parse(localStorage.getItem('user')).user
    await this.getStore({ id: user.storeId })
    if (this.store) {
      this.form.address.street = this.store.address.street
      this.form.address.commune = this.store.address.commune
      this.storeForm.contactAccountName = this.store.contactAccountName
      this.storeForm.contactPhone = this.store.contactPhone
      this.storeForm.contactEmail = this.store.contactEmail
      if (this.store.storeOwner) {
        this.storeForm.storeOwner = this.store.storeOwner
      } else {
        this.storeForm.storeOwner = `${this.store.users[0].name} ${this.store.users[0].lastName}`
      }
    }
    await this.listExceptions({
      params: {
        date: this.$moment(new Date(new Date().setDate(new Date().getDate() + 1))).format('YYYY-MM-DD')
      }
    })
    await this.listWithdrawal({
      params: {
        date: this.$moment(new Date(new Date().setDate(new Date().getDate() + 1))).format('YYYY-MM-DD'),
        storeId: this.store.id
      }
    })
    this.setNewWithdrawal()
  },
  data () {
    return {
      newWithdrawal: false,
      isException: false,
      lateHours: false,
      notValidDay: false,
      noExceptionAllowed: false,
      edit: false,
      form: {
        WithDrawalDate: new Date(new Date().setDate(new Date().getDate() + 1)),
        address: {
          street: null,
          commune: null
        }
      },
      storeForm: {
        contactAccountName: null,
        contactEmail: null,
        contactPhone: null,
        storeOwner: null
      },
      fields: [
        { label: 'Nombre Tienda', key: 'name' },
        { label: 'Direccion de retiro', key: 'address' },
        { label: 'Comuna', key: 'commune' },
        { label: 'Encargado Entrega', key: 'storeOwner' },
        { label: 'Telefono', key: 'contactPhone' },
        { label: 'Instagram', key: 'contactAccountName' },
        { label: 'Fecha Retiro', key: 'withdrawalDate' }
      ]
    }
  },
  computed: {
    ...mapGetters(['store', 'exceptions', 'withdrawals']),
    withdrawalData () {
      if (this.withdrawals.length > 0 && this.store) {
        return this.withdrawals.map(withdrawal => {
          return {
            withdrawal,
            name: withdrawal.store.name,
            state: withdrawal.isAssign,
            address: withdrawal.address.street,
            apartment: withdrawal.apartment,
            commune: withdrawal.address.commune,
            storeOwner: withdrawal.store.storeOwner ? withdrawal.store.storeOwner : `${this.store.users[0].name} ${this.store.users[0].lastName}`,
            contactPhone: withdrawal.store.contactPhone,
            contactAccountName: withdrawal.store.contactAccountName,
            withdrawalDate: monthString(new Date(withdrawal.WithDrawalDate))
          }
        })
      }
      return []
    }
  },
  methods: {
    ...mapActions(['getStore', 'createWithdrawal', 'listExceptions', 'updateStore', 'listWithdrawal', 'updateWithdrawal', 'checkWithdrawalHour']),
    async setEdit (withdrawal) {
      if (!this.edit) {
        this.edit = true
        return
      }
      if (this.edit) {
        this.edit = false
        const data = { ...withdrawal.withdrawal }
        data.address.street = withdrawal.address
        data.address.commune = withdrawal.commune
        await this.updateWithdrawal({ id: withdrawal.withdrawal.id, data })
      }
    },
    setNewWithdrawal () {
      /*
        Siempre se agenda el retiro para el dia de mañana,
        los unicos dias que no se pueden agendar son domingos y lunes
        el horario es de 9:00-22:00
      */
      const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)) // Se obtiene la fecha del dia siguiente
      const day = tomorrow.getDay()
      /*
        Al menos que haya una exepcion no se puede agendar fechas para el domingo o el lunes
        en dias UTC el domingo es 0 y el lunes es 1
      */
      const restrictDays = (day === 1 || day === 0)
      if (this.lateHours) this.newWithdrawal = false
      if (restrictDays) {
        // Si la fecha no esta dentro de los dias no se puede agendar
        this.notValidDay = true
        this.newWithdrawal = false
      }
      if (!this.lateHours && day > 1 && !restrictDays) this.newWithdrawal = true
      if (this.exceptions.length > 0) {
        // Hay excepciones
        // Las excepciones  que se toman en cuenta son para el dia siguiente del retiro.
        if (this.exceptions[0].allowRemove && !this.lateHours) {
          /*
            Si la excepcion permite agendar fecha para un dia que no se pueda y
            esta dentro el horario permitido se podra agendar
          */
          this.newWithdrawal = true
          this.notValidDay = false
        } else if (!this.exceptions[0].allowRemove) {
          // Excepcion que no permite agendar fecha para ese dia.
          this.noExceptionAllowed = true
          this.notValidDay = true
          this.newWithdrawal = false
          this.isException = true
        }
      }
    },
    async setWithdrawal () {
      const data = {
        addressId: this.store.addressId,
        storeId: this.store.id,
        WithDrawalDate: this.form.WithDrawalDate,
        address: this.store.address
      }
      data.address.street = this.form.address.street
      data.address.commune = this.form.address.commune
      const storeForm = { ...this.store }
      storeForm.contactAccountName = this.storeForm.contactAccountName
      storeForm.contactPhone = this.storeForm.contactPhone
      storeForm.contactEmail = this.storeForm.contactEmail
      storeForm.storeOwner = this.storeForm.storeOwner
      const vm = this
      const response = await this.createWithdrawal({ data, vm })
      if (response.status === 200 || response.status === 201) {
        const user = JSON.parse(localStorage.getItem('user')).user
        await this.updateStore({ id: this.store.id, data: storeForm })
        await this.listWithdrawal({
          params: {
            date: this.$moment(new Date(new Date().setDate(new Date().getDate() + 1))).format('YYYY-MM-DD'),
            storeId: this.store.id
          }
        })
        await this.getStore({ id: user.storeId })
        this.makeSuccessToast('Se ha agendado el envio correctamente')
      }
    }
  }
}
</script>

<style lascoped>
.sub-title {
  font-weight: 600;
  color: #495057;
}
.assignBox {
  padding: 5px;
  border-radius: 5px;
  width: 80%;
}
</style>
