<template lang="pug">
.page-content.page-container#page-content
  .padding
    .row
      .col-lg-12.md-6
        p Id de envío: {{ delivery.id || delivery.actions.id }}
        .timeline.p-0.block.mb-4
          .tl-item.active
            div(:class="`tl-dot ${historyUpdates && colorState[historyUpdates[0].state] || colorState.default}`")
            .tl-content
              div().text-capitalize Estado actual: {{ historyUpdates && historyUpdates[0].state || 'Por Entregar'}}
              .tl-date.text-muted.mt-1 Fecha y hora: {{ historyUpdates && historyUpdates[0].timestamp }}
              .tl-date.text-muted.mt-1.text-capitalize Motivo: {{ historyUpdates && reasons[historyUpdates[0].reason] }}
              .tl-date.text-muted.mt-1 Comentario: {{ historyUpdates && historyUpdates[0].comment }}
              .tl-date.text-muted.mt-1 Usuario: {{ historyUpdates && historyUpdates[0].userName }}
          .tl-item(v-for="update in historyUpdates && historyUpdates.slice(1)")
            div(:class="`tl-dot ${colorState[update.state] || colorState.default}`" :key="update.state +1")
            .tl-content
              div().text-capitalize {{ update.state || 'Por Entregar'  }}
              .tl-date.text-muted.mt-1 Fecha y hora: {{ update.timestamp }}
              .tl-date.text-muted.mt-1.text-capitalize Motivo: {{ reasons[update.reason] }}
              .tl-date.text-muted.mt-1 Comentario: {{ update.comment }}
              .tl-date.text-muted.mt-1 Usuario: {{ update.userName }}
          .tl-item.pb-4

//- div
//-   .text
//-     strong Id de envío: {{ delivery.actions.id }}

//-   ul(v-for="update in historyUpdates")
//-     div(:key="update.deliveryId")
//-       //- hr.linea
//-       li
//-         strong Estado: {{ update.state || 'Por entregar' }}
//-       li
//-         strong Motivo: {{ reasons[update.reason] }}
//-       li
//-         strong Comentario: {{ update.comment }}
//-       li
//-         strong Fecha y hora: {{ update.timestamp }}
//-       li
//-         strong Usuario: {{ update.userName }}

</template>
<script>
import reasons from '../../plugins/enums/delivery/reasons.js'

export default {
  props: {
    historyUpdates: {
      type: Array
    },
    delivery: {
      type: Object
    }
  },
  data () {
    return {
      reasons,
      colorState: {
        default: 'b-primary',
        pendiente: 'b-warning',
        entregado: 'b-success',
        rechazado: 'b-danger'
      }
    }
  }
}
</script>

<style scoped>
body {
    background-color: #f9f9fa
}

@media (min-width:992px) {
    .page-container {
        max-width: 1140px;
        margin: 0 auto
    }

    .page-sidenav {
        display: block !important
    }
}

.padding {
    padding: 1rem 2rem
}

.w-32 {
    width: 32px !important;
    height: 32px !important;
    font-size: .85em
}

.tl-item .avatar {
    z-index: 2
}

.circle {
    border-radius: 500px
}

.gd-warning {
    color: #fff;
    border: none;
    background: #f4c414 linear-gradient(45deg, #f4c414, #f45414)
}

.timeline {
    position: relative;
    border-color: rgba(160, 175, 185, .15);
    padding: 0;
    margin: 0
}

.p-4 {
    padding: 1.5rem !important
}

.block,
.card {
    background: #fff;
    border-width: 0;
    border-radius: .25rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .05);
    margin-bottom: 1.5rem
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important
}

.tl-item {
    border-radius: 3px;
    position: relative;
    display: -ms-flexbox;
    display: flex
}

.tl-item>* {
    padding: 10px
}

.tl-item .avatar {
    z-index: 2
}

.tl-item:last-child .tl-dot:after {
    display: none
}

.tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, .2)
}

.tl-item:last-child .tl-dot:after {
    display: none
}

.tl-item.active .tl-dot:before {
    border-color: #34C38F!important;
    /* background-color: #34C38F; */
    box-shadow: 0 0 0 4px rgba(68, 139, 255, .2)
}

.tl-dot {
    position: relative;
    border-color: rgba(160, 175, 185, .15)
}

.tl-dot:after,
.tl-dot:before {
    content: '';
    position: absolute;
    border-color: inherit;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%)
}

.tl-dot:after {
    width: 0;
    height: auto;
    top: 25px;
    bottom: -15px;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0
}

tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, .2)
}

.tl-dot {
    position: relative;
    border-color: rgba(160, 175, 185, .15)
}

.tl-dot:after,
.tl-dot:before {
    content: '';
    position: absolute;
    border-color: inherit;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%)
}

.tl-dot:after {
    width: 0;
    height: auto;
    top: 25px;
    bottom: -15px;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0
}

.tl-content p:last-child {
    margin-bottom: 0
}

.tl-date {
    font-size: .88em;
    margin-top: 2px;
    min-width: 100px;
    /* max-width: 100px */
}

.avatar {
    position: relative;
    line-height: 1;
    border-radius: 500px;
    white-space: nowrap;
    font-weight: 700;
    border-radius: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-radius: 500px;
    box-shadow: 0 5px 10px 0 rgba(50, 50, 50, .15)
}

.b-warning {
    border-color: #f4c414!important;
}

.b-primary {
    border-color: #448bff!important;
}

.b-danger {
    border-color: #f54394!important;
}
.b-success {
  border-color: #34C38F!important;
}
.tl-item.active .tl-dot:before {
  background-color: red
}
</style>
