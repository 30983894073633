<template lang="pug">
  div.tabla.sombra
    .mb-2
        h3.d-inline.mr-3 Tiendas
        b-button.boton(v-b-modal.create-store-form-modal) Ingresar Nueva
    b-table(:fields="fields" :items="storeData")
      template(#cell(actions)="{value}")
        b-button.mr-2.mb-1.boton-editar(@click="$router.push(`/tiendas/${value.id}`)")
          .material-icons create
        b-button.mb-1.boton-eliminar(@click="onDeleteUser(value)")
          .material-icons delete_outline
      template(v-slot:cell(name)="{item}")
        .columna {{item.name}}
    b-modal#create-store-form-modal(ref="createStoreFormModal" hide-footer title="Creando Tienda")
      store-form(@done="onDoneUserModal")
    b-modal(ref="updateStoreFormModal" hide-footer title="Editando Tienda")
      store-form(@done="onDoneUserModal" :store="selectedStore" :editMode="true")
</template>

<script>
import StoreForm from './StoreForm'
import { mapActions, mapGetters } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  components: {
    StoreForm
  },
  data () {
    return {
      selectedStore: null,
      fields: [
        { label: 'Nombre', key: 'name' },
        { label: 'Activo', key: 'isActive' },
        { label: 'Acciones', key: 'actions' }
      ]
    }
  },
  methods: {
    ...mapActions(['listStore', 'deleteStore']),
    onDoneUserModal () {
      this.listStore()
      this.$refs.createStoreFormModal.hide()
      this.$refs.updateStoreFormModal.hide()
    },
    async onDeleteUser (store) {
      const ok = await this.$bvModal.msgBoxConfirm(`¿Está seguro que desea eliminar la tienda ${store.name}?`)
      if (!ok) return

      const response = await this.deleteStore({ id: store.id })
      if (response.status === 200) {
        this.makeSuccessToast('La tienda se eliminó correctamente')
      } else if (response.status >= 400 && response.status < 500) {
        this.makeDangerToast(response.data.message || 'Error al eliminar usuario')
      }
      await this.listStore()
    },
    openEditModal (store) {
      this.selectedStore = store
      this.$refs.updateStoreFormModal.show()
    }
  },
  computed: {
    ...mapGetters(['stores']),
    storeData () {
      return this.stores.reduce((acum, store) => acum.concat({
        name: store.name,
        isActive: store.isActive ? 'SI' : 'NO',
        actions: store
      }), [])
    }
  },
  created () {
    this.listStore()
  }
}
</script>
