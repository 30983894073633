export default `
.package-label-template {
  border: 2px solid black;
  height: 121mm;
  width: 85mm;
  border-radius: 20px;
  font-size: 3.9mm;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
}
.logo-store {
  display: flex;
  justify-content: center;
}
.store-name {
  font-size: 4.5mm;
  text-transform: uppercase;
}
.commune {
  position: relative;
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: -4.5mm;
  text-transform: uppercase;
  z-index: 99;
}
.logo-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9.7mm;
  font-weight: bold;
  background: #e0e0e0 solid;  
}
.text-center {
  text-align: center;
}
.divider {
  border-bottom: 1px solid black;
  width: 100%;
}
.qr-code {
  text-align: center;
}
.delivery-code {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: 600;
}
.delivery-label__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid black;
  border-radius: 20px 20px 20px 0;
  padding: 0.364cm;
  width: 53.3mm;
}
.footer {
  display: flex;
  justify-content: space-between;
  border: 1px solid black;
  border-radius: 0 0 20px 20px;
  margin-top: 6.06mm;
  padding: 0.364cm 0.485cm;
}
.content {
  padding: 0.243cm 0.364cm;
}
.header {
  display: flex;
  padding: 4mm 0mm 3mm 0mm;
  gap: 3.64mm;
  justify-content: center;
}
.brand {
  font-style: italic;
}
img {
  width: 50mm;
  height: 50mm;
  margin-bottom: -6mm;
}
body {
  margin: 0;
}
.info {
  font-size: 3.64mm;
  font-weight: 400;
}
.info-item {
  margin-bottom: 1.22mm;
}
@media print {
  @page {
    size: 85mm 121mm;
    margin: 0;
  }
}`
