<template lang="pug">
  div
    delivery-state-cards.mb-4
    .tabla.sombra
      b-form(@submit.stop.prevent="fetchData()")
        b-form-row(align-content="start")
          b-col.mb-2(cols="12" md="auto")
            //- h3 {{ store ? store.name : '' }} Envíos
            h3 Envíos
          b-col.mb-2(cols="12" md="3")
            b-form-input(v-model="filterOptions.search" placeholder="buscar")
          b-col.mb-2(cols="12" md="3")
            b-form-select(v-model="filterOptions.state")
              b-form-select-option(:value="null") Selecciona un Estado
              b-form-select-option(:value="'NULL'") Por entregar
              b-form-select-option(:value="'entregado'") Entregado
              b-form-select-option(:value="'pendiente'") Pendiente
              b-form-select-option(:value="'rechazado'") Rechazado
          b-col.mb-2(cols="12" md="3" lg="2")
            b-form-datepicker(
              label-no-date-selected="sin fecha"
              v-model="filterOptions.date"
              :date-format-options="datepickerFormat"
              reset-button
            )
          b-col.mb-2(cols="12" md="auto")
            b-button(type="submit" block) Filtrar
          b-col.mb-2(cols="12" md="auto")
            b-dropdown(text="Acciones")
              b-dropdown-item-button(@click="printSelectedPackages()") Imprimir Seleccionados
      b-table(:fields="fields" :items="deliveryData" responsive )
        template(#head(select))
          b-form-checkbox(v-model="selectedAllIds" @change="toggleSelectAll")
        template(#cell(select)="{item}")
          b-form-checkbox(:value="item.id" v-model="selectedIds" @change="handlePackageToPrint(item.actions)")
        template(#cell(actions)="{value}")
          .botones-accion
            b-button.mr-2.mb-1.boton-ver(@click="$router.push(`/envio/${value.id}`)")
              .material-icons remove_red_eye
            b-button.mr-2.mb-1.boton-imprimir(@click="printPackageLabel(value)")
              .material-icons print
        template(#cell(state)="{value}")
          div
            b-badge(v-if="value === 'Entregado'" variant="success") {{ value }}
            b-badge(v-else-if="value === 'Rechazado'" variant="danger") {{ value }}
            b-badge(v-else-if="value === 'Pendiente'" variant="warning").text-white {{ value }}
            b-badge(v-else variant="light") {{ value }}
      b-pagination-nav(
        align="center"
        :number-of-pages="totalPages ? totalPages : 1"
        :link-gen="linkGen"
        v-model="currentPage"
      )
      PrintAllPackagesLabel(ref="allPackagesLabelTemplate")
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import DeliveryStateCards from './DeliveryStateCards'
import PrintAllPackagesLabel from './printPackages/PrintAllPackagesLabel.vue'

export default {
  props: {
    deliveryList: Array
  },
  components: {
    DeliveryStateCards,
    PrintAllPackagesLabel
  },
  data () {
    return {
      datepickerFormat: { year: 'numeric', month: 'numeric', day: 'numeric' },
      currentPage: 1,
      totalPages: null,
      selectedIds: [],
      selectedPackages: [],
      selectedAllIds: false,
      filterOptions: {
        date: this.$moment().format('YYYY-MM-DD'),
        search: '',
        page: 0,
        state: null,
        size: 50
      },
      fields: [
        { key: 'select' },
        { label: 'Id', key: 'id' },
        { label: 'Cliente', key: 'customerName' },
        { label: 'Dirección', key: 'address' },
        { label: 'Comuna', key: 'commune' },
        { label: 'Teléfono', key: 'customerPhone' },
        { label: 'Fecha', key: 'date' },
        { label: 'Estado', key: 'state' },
        { label: 'Acciones', key: 'actions' }
      ]
    }
  },
  methods: {
    ...mapActions(['listDelivery', 'getStore', 'getCountStates']),
    linkGen (pageNum) {
      return {
        path: '/envios/',
        query: { page: pageNum }
      }
    },
    handleResize () {
      this.ancho = window.innerWidth
    },
    async fetchData () {
      const response = await this.listDelivery({ params: { ...this.filters } })
      this.getCountStates({ params: { ...this.filters } })
      if (response) {
        this.totalPages = response.data.totalPages
      }
    },
    toggleSelectAll (value) {
      if (value) {
        this.selectedIds = this.allDeliveryIds
        this.selectedPackages = [...this.deliveries]
      } else {
        this.selectedIds = []
        this.selectedPackages = []
      }
    },
    printPackageLabel (item) {
      this.$refs.allPackagesLabelTemplate.print([this.getLabelContext(item)])
      // this.getLabelContext(item)
    },
    getLabelContext (item) {
      // console.log(item, 'este es el objeto a imprimir')
      return {
        id: item.id,
        customerName: item.customerName,
        customerPhone: item.customerPhone,
        customerAddress: item.address ? item.address.street : '',
        customerFullAddress: item.address ? `${item.address.street}, ${item.address.commune}` : '',
        commentary: item.commentary,
        commune: item.address ? item.address.commune : '',
        storeName: item.store ? item.store.name : '',
        contactAccountName: item.store ? item.store.contactAccountName : '',
        qrCode: item.qrCode
      }
    },
    handlePackageToPrint (item) {
      // console.log(item)
      // console.log(item, 'item para imprimir', checked, 'value')
      const filtered = this.selectedPackages.filter((p) => p.id === item.id)
      if (filtered.length) {
        this.selectedPackages = this.selectedPackages.filter((p) => p.id !== item.id)
      } else {
        this.selectedPackages.push(item)
      }
      // console.log(this.selectedPackages, 'paquetes para imprimir')
    },
    printSelectedPackages () {
      const selectedPackages = this.selectedPackages
      if (selectedPackages.length === 0) {
        return this.$bvToast.toast('No hay envíos seleccionados', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
      }
      const packagesContext = selectedPackages.map(p => this.getLabelContext(p))
      this.$refs.allPackagesLabelTemplate.print(packagesContext)
    }
  },
  computed: {
    ...mapGetters(['deliveries', 'store', 'user']),
    deliveryData () {
      let items = []
      if (this.deliveries) {
        items = this.deliveries.reduce((acum, delivery) => acum.concat({
          id: delivery.id,
          customerName: delivery.customerName,
          address: delivery.address.street,
          commune: delivery.address.commune,
          state: delivery.state ? delivery.state.replace(/^./, delivery.state[0].toUpperCase()) : 'Por entregar',
          date: this.$moment(delivery.date.substring(0, 10)).format('DD-MM-YYYY'),
          customerPhone: delivery.customerPhone,
          actions: delivery
        }), [])
      }
      return items
    },
    filters () {
      const filter = {}
      for (const key in this.filterOptions) {
        if (this.filterOptions[key] !== null && this.filterOptions[key] !== '') {
          filter[key] = this.filterOptions[key]
        }
      }
      return filter
    },
    allDeliveryIds () { return this.deliveries.map(delivery => delivery.id) }
  },
  created () {
    this.fetchData()
    this.getStore({ id: this.user.storeId })
    if (window.innerWidth < 650) {
      this.filterOptions.size = 6
    }
  },
  watch: {
    currentPage (valor) {
      this.filterOptions.page = valor - 1
      this.fetchData()
    },
    selectedIds () {
      this.selectedAllIds = this.selectedIds.length === this.allDeliveryIds.length
    }
  }
}
</script>

<style lang="scss" scoped>
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #869099;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #ced4da;
}
</style>
